import React, { useEffect, useState } from 'react';
import ChatListIntents from './ChatListIntents';
import { useSnapshot } from 'valtio';
import { state } from '../store';


export default function ChatIntents() {
    const snap = useSnapshot(state)
    const [open, setOpen] = useState(false)
    const [desktopList, setDesktopList] = useState(false)

    // Se la larghezza dello schermo e' maggiore di 1140p, open e' true
    useEffect(() => {
       /*  if(window.innerWidth >= 1140){
            setOpen(true)
        } else {
            setOpen(false)
        }  */
        setOpen(true)
    }, [window.innerWidth])

 

    return (
        <>
            <div id="chatIntents" className={desktopList ? "open" : ""}>
                {open && 
                    <ChatListIntents 
                        setOpen={setOpen}
                        desktopList={desktopList}
                        setDesktopList={setDesktopList}
                    /> 
                }
               {/*  <button 
                    className={`toggle ${open ? "active" : ""}`}
                    onClick={() => setOpen(!open)}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.9906 2.92969C13.7226 2.92969 12.5406 3.7617 12.1716 4.9317L3.9906 4.92969C3.4386 4.92969 2.9906 5.37769 2.9906 5.92969C2.9906 6.48169 3.4386 6.92969 3.9906 6.92969L12.1716 6.92871C12.6196 8.15371 13.7226 8.92969 14.9906 8.92969C16.2586 8.92969 17.3726 8.15171 17.8286 6.92071L19.9906 6.92969C20.5426 6.92969 20.9906 6.48169 20.9906 5.92969C20.9906 5.37769 20.5426 4.92969 19.9906 4.92969H17.8206C17.3386 3.71069 16.2586 2.92969 14.9906 2.92969ZM8.9906 8.92969C7.6726 8.92969 6.5696 9.7497 6.16659 10.9317C6.0256 10.9407 3.9906 10.9297 3.9906 10.9297C3.4386 10.9297 2.9906 11.3777 2.9906 11.9297C2.9906 12.4817 3.4386 12.9297 3.9906 12.9297C3.9906 12.9297 6.04259 12.9087 6.17059 12.9217C6.57359 14.1037 7.6726 14.9297 8.9906 14.9297C10.2586 14.9297 11.3446 14.1527 11.8196 12.9327L19.9906 12.9297C20.5426 12.9297 20.9906 12.4817 20.9906 11.9297C20.9906 11.3777 20.5426 10.9297 19.9906 10.9297L11.8146 10.9207C11.3766 9.72771 10.2586 8.92969 8.9906 8.92969ZM14.9906 14.9297C13.7226 14.9297 12.5626 15.7647 12.1706 16.9197L3.9906 16.9297C3.4386 16.9297 2.9906 17.3777 2.9906 17.9297C2.9906 18.4817 3.4386 18.9297 3.9906 18.9297L12.1566 18.9307C12.5706 20.1157 13.7226 20.9297 14.9906 20.9297C16.2586 20.9297 17.3516 20.1367 17.8326 18.9307L19.9906 18.9297C20.5426 18.9297 20.9906 18.4817 20.9906 17.9297C20.9906 17.3777 20.5426 16.9297 19.9906 16.9297L17.8236 16.9237C17.3926 15.7407 16.2586 14.9297 14.9906 14.9297Z" fill="#681EF5"/>
                    </svg>
                </button> */}
                

            </div>
        </>
    );
}
