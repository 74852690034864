import React, { useRef, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { state } from '../store.js';
import Swiper from 'swiper';
import 'swiper/css';



export default function ChatListActions() {
    const snap = useSnapshot(state);

    if (snap.setup.actions.length === 0) {
        return null;
    }

    const chatActions = useRef()
    const chatActionsToggle = useRef()


    const handleButtonClick = (action) => {
        if(action.action) {
            window.open(action.action);
            return;
        }
        state.session.actions.lockControls()
        state.session.actions.addNewMessage({"text": action.message}, 'user', 'waiting')
        chatActions.current.classList.remove('active');
        chatActionsToggle.current.classList.remove('active');
        setTimeout(() => {
            state.session.actions.addNewMessage(null, 'agent', 'loading')
        }, 500);
    };

    useEffect(() => {
        if (chatActionsToggle.current) {
            chatActionsToggle.current.addEventListener("click", (event) => {
                chatActions.current.classList.toggle('active');
                chatActionsToggle.current.classList.toggle('active');
            });
        }

        if (chatActions.current) {
            new Swiper(chatActions.current, {
                loop: false,
                slidesPerView: "auto",
                spaceBetween: '6px',
                freeMode: true,
                grabCursor: true,

            });
        }
    }, []);


    return (
        <>
           <div id="chatListActions"  className='active'>
                {snap.setup.actions.map((action, index) => (
                    <button key={index} onClick={() => handleButtonClick(action)}>
                        {action.label}
                    </button>
                ))}
            </div>
        </>
    );
}
