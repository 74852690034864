import { createRoot } from 'react-dom/client';
import DateTimePicker from '../utility/DateTimePicker';


function chatCreateFormElement(element, parent) {
    if(element.type === "text" || element.type === "email" || element.type === "tel" || element.type === "date" || element.type === "time"){
        let input = document.createElement("input");
        input.type = element.type;
        input.value = element.value;
        input.id = element.id;
        input.placeholder = element.placeholder;
        input.name = element.name;

        if(element.required){
            input.required = "required"
        }

        if(element.type === "time"){
            roundedDateTime = roundToNearest15Minutes();
            input.value = roundedDateTime

            if(input.min = element.min){ input.min = element.min;}
            if(input.max = element.max){ input.max = element.max;}
        }

        if(element.type === "date"){
            input.valueAsDate = new Date();
        }

        parent.appendChild(input);
    } else if(element.type === "select"){
        let select = document.createElement("select");
        select.id = element.id;
        select.name = element.name;

        if(element.required){
            select.required = "required"
        }

        element.options.forEach(element => {
            let option = document.createElement("option");
            option.value = element.value;
            option.innerHTML = element.value;

            if(element.selected){
                option.selected = true;
            }

            if(element.hidden){
                option.hidden = true;
            }

            select.appendChild(option);
        });

        parent.appendChild(select);
    } else if(element.type === "geolocation"){
        let button = document.createElement("button");
        button.id = "geolocation";
        button.classList.add('geolocation');
        let buttonSpan = document.createElement("span");
        buttonSpan.innerHTML += element.label;
        button.appendChild(buttonSpan);
        parent.appendChild(button);

        let lat = document.createElement("input");
        lat.name = "geoLat";
        lat.type = "hidden";
        if(element.required){ lat.required = "required"}
        parent.appendChild(lat);

        let long = document.createElement("input");
        long.name = "geoLong";
        long.type = "hidden";
        if(element.required){ long.required = "required"}
        parent.appendChild(long);


        button.addEventListener("click", () => {
            button.disabled = true;
            button.classList.remove('success');
            button.classList.remove('error');
            button.classList.add('loading');
            setTimeout(() => {
                buttonSpan.innerHTML = "";
                long.value = 45;
                lat.value = 12;
                button.classList.remove('loading');
                button.classList.add('success');
            }, 500);
          /*  if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition, showError);
                button.disabled = false;
            } else {
                button.classList.remove('loading');
                buttonSpan.innerHTML = "Geolocation is not supported";
                button.disabled = false;
                button.classList.add('error');
            }*/
        });

        function showPosition(position) {
            buttonSpan.innerHTML = "";
            long.innerHTML = position.coords.longitude;
            lat.innerHTML = position.coords.latitude;
            button.classList.remove('loading');
            button.classList.add('success');
        }

        function showError(error) {
            switch(error.code) {
                case error.PERMISSION_DENIED:
                    buttonSpan.innerHTML = "User denied the request for Geolocation."
                    button.classList.remove('loading')
                    button.classList.add('error');
                break;
                case error.POSITION_UNAVAILABLE:
                    buttonSpan.innerHTML = "Location information is unavailable."
                    button.classList.remove('loading')
                    button.classList.add('error');
                break;
                case error.TIMEOUT:
                    buttonSpan.innerHTML = "The request to get user location timed out."
                    button.classList.remove('loading')
                    button.classList.add('error');
                break;
                case error.UNKNOWN_ERROR:
                    buttonSpan.innerHTML = "An unknown error occurred."
                    button.classList.remove('loading')
                    button.classList.add('error');
                break;
            }
        }


    } else if(element.type === "textarea"){
        let input = document.createElement("textarea");
        input.innerHTML = element.value;
        input.id = element.id;
        input.placeholder = element.placeholder;
        input.name = element.name;
        input.rows = element.rows;
        input.cols = element.cols;

        if(element.required){
            input.required = "required"
        }

        parent.appendChild(input);
    } else if(element.type === "checkbox"){
        let input = document.createElement("input");
        input.type = element.type;
        input.value = element.value;
        input.id = element.id;
        input.name = element.name;

        if(element.required){
            input.required = "required"
        }

        if(element.label){
            let label = document.createElement("label");
            label.for = element.name
            label.innerHTML = element.label

            label.appendChild(input);
            parent.appendChild(label);
        } else {
            parent.appendChild(input);
        }
    } else if(element.type === "file"){
        let input = document.createElement("input");
        input.type = element.type;
        input.id = element.id;
        input.name = element.name;
        input.accept = element.accept;

        if(parent.tagName.toLowerCase() === 'form'){
            parent.setAttribute('enctype', 'multipart/form-data');
        }

        if(element.multiple){
            input.multiple = true
        }

        if(element.capture){
            input.setAttribute("capture", "");
        }

        if(element.required){
            input.required = "required"
        }

        parent.appendChild(input);

    } else if (element.type === "div"){
        let div = document.createElement("div");
        if(element.class){
            div.classList.add(element.class);
        }

        if(element.content){
            element.content.forEach(child => {
                chatCreateFormElement(child, div);
            });
        }
        parent.appendChild(div);

    } else if(element.type === "appointment"){
        const appointmentElement = document.createElement("div");
        parent.appendChild(appointmentElement);
    
        const root = createRoot(appointmentElement);
        root.render(<DateTimePicker style={element.style} availability={element.availability.slots} timezone={element.availability.timezone} />);
          
    } else if(element.type === "button"){
        let button = document.createElement("button");
        button.classList.add('button')
        button.innerHTML = element.value;
        button.id = element.id;
        button.type = "submit";

        parent.appendChild(button);
    }
}

function roundToNearest15Minutes() {
    const roundedDateTime = new Date();
    const minutes = roundedDateTime.getMinutes();
    const remainder = minutes % 15;

    if (remainder <= 7) {
        roundedDateTime.setMinutes(minutes - remainder);
    } else {
        roundedDateTime.setMinutes(minutes + (15 - remainder));
    }

    const roundedHours = roundedDateTime.getHours();
    const roundedMinutes = roundedDateTime.getMinutes();

    return roundedHours+":"+roundedMinutes;
}

export default chatCreateFormElement;