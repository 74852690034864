import moment from 'moment';
import { useRef, useEffect} from 'react'
import playNotification from '../utility/playNotification';
import chatScrollToBottom from '../utility/chatScrollToBottom';
import chatCreateFormElement from '../utility/chatCreateFormElement';

import { state } from '../store.js';
import { useSnapshot } from 'valtio'

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function ChatMessage(props)
{

  const snap = useSnapshot(state)

  const { id, sendAt, sender, status, updateMessageStatus, response} = props;

  const messageElement = useRef()
  const messageContent = useRef()
  const messageBody = useRef()
  const messageLoadingIcon = useRef()

  if(status == 'loading' ){
    useEffect(() => {
      messageLoadingIcon.current.innerHTML = ` <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff"> <circle cx="15" cy="15" r="15"> <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/> <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/> </circle> <circle cx="60" cy="15" r="9" fill-opacity="0.3"> <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite"/> <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"/> </circle> <circle cx="105" cy="15" r="15"> <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/> <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/> </circle> </svg>`;
      chatScrollToBottom();
    }, []);
    return (
      <li className={sender + ' messageElement'} key={id} >
        <div className="messageElementHead">
          {sender == 'agent' ? (
            <img
              src={snap.setup.image}
              alt={snap.setup.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src="/static/images/user.svg";
              }}
            />
          ) : (
            <img src="/static/images/user.svg" alt="User" />
          )}
        </div>
        <div className="messageBody">
          <div className="messageContent" ref={messageContent}>
            <div className="messageLoadingIcon" ref={messageLoadingIcon}></div>
            <small>{moment.unix(sendAt).format("HH:mm")}</small>
          </div>
        </div>

      </li>


    );
  }

  useEffect(() => {

    if(status == 'waiting' ){
      playNotification();
      updateMessageStatus(props.index);
    }

    //addContent
    if(response.parent){
      messageContent.current.classList.add("child");

      const parent = document.createElement("div");
      parent.classList.add("parent");

      const agent = document.createElement("strong");
      agent.innerHTML = state.setup.name;
      parent.appendChild(agent);

      const message = document.createElement("small");
      message.innerHTML = response.parent.replace(/(<([^>]+)>)/gi, "");
      parent.appendChild(message);

      messageContent.current.appendChild(parent);
  }

    if (response.text && response.text !== "") {
      const textElement = document.createElement("p");
      // If exist in the text a link, add target blank to the link
      if (response.text.includes('<a')) {
        textElement.innerHTML = response.text.replace(/<a/g, '<a target="_blank"');
      } else {
        textElement.innerHTML = response.text;
      }

  
      messageContent.current.appendChild(textElement);
    }


    if (response.video) {
      const ctaButton = document.createElement("a");
      ctaButton.classList.add('video');
      ctaButton.href = response.video.url;
      ctaButton.target = '_blank';
      ctaButton.innerHTML = `<span class="icon">🎥</span><p>${response.video.label}</p>`;
      messageContent.current.appendChild(ctaButton);
    }


    if (response.image) {
      const imageElement = document.createElement("img");
      imageElement.src = response.image;
      messageContent.current.appendChild(imageElement);
    }

    if (response.cta) {
      const ctaButton = document.createElement("a");
      ctaButton.href = response.cta.url;
      ctaButton.textContent = response.cta.label;
      messageContent.current.appendChild(ctaButton);
    }

    if (response.document) {
      const ctaButton = document.createElement("a");
      ctaButton.classList.add('document');
      ctaButton.href = response.document.url;
      ctaButton.target = '_blank';
      ctaButton.innerHTML = `<span class="icon">📄</span><p>${response.document.label}</p>`;
      messageContent.current.appendChild(ctaButton);
    }

    if (response.button) {
      const ctaButton = document.createElement("a");
      ctaButton.classList.add('button');
      ctaButton.href = response.button.url;
      ctaButton.textContent = response.button.label;
      messageContent.current.appendChild(ctaButton);
    }

    if (response.slider) {
      const sliderContainer = document.createElement("div");
      sliderContainer.classList.add("swiper-container");

      const navigation = document.createElement("div");
      navigation.classList.add("navigation");
      const nextButton = document.createElement("div");
      nextButton.classList.add("swiper-button-next");
      const prevButton = document.createElement("div");
      prevButton.classList.add("swiper-button-prev");
      navigation.appendChild(prevButton);
      navigation.appendChild(nextButton);

      const sliderWrapper = document.createElement("div");
      sliderWrapper.classList.add("swiper-wrapper");

      response.slider.forEach(slide => {
          const slideCard = document.createElement("div");
          slideCard.classList.add("swiper-slide");

          const slideContent = document.createElement("div");
          slideContent.classList.add('flex');
          slideContent.innerHTML = `
              <img src="${slide.image}" alt="${slide.title}" />
              <div class="text">
                  <h3>${slide.title}</h3>
                  <p>${slide.description}</p>
                  <a target="_blank" href="${slide.url}" class="cta">${slide.label}</a>
              </div>
          `;

          slideCard.appendChild(slideContent);
          sliderWrapper.appendChild(slideCard);
      });

      sliderContainer.appendChild(sliderWrapper);

      messageContent.current.appendChild(sliderContainer);
      messageContent.current.appendChild(navigation);

      new Swiper(sliderContainer, {
        modules: [Navigation, Pagination],
        loop: false,
        slidesPerView: 1,
        spaceBetween: 16,
        freeMode: false,
        grabCursor: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
      });
    }

    if (response.form) {
      const form = document.createElement("form");

      response.form.elements.forEach(element => {
        chatCreateFormElement(element, form)
      });

      form.addEventListener("submit", function(e) {
          e.preventDefault();
          e.target.classList.add('send')
          var formData = new FormData(e.target);
          if(document.getElementById('datePicker')) {
              formData.set('date', changeDateFormat(document.getElementById('datePicker').value))
          }
          state.session.actions.lockControls()

          fetch(response.form.action, {
              method: response.form.method,
              body: formData,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
              },
              mode: 'no-cors'
          })
          .then(response => {
            state.session.actions.addNewMessage({"text": "<p>Richiesta inviata con successo!</p>"}, 'agent', 'waiting')
            state.session.actions.unlockControls()
          })
          .catch(error => {
              console.error('Si è verificato un errore durante la richiesta all\'API:', error);
              setTimeout(() => {
                state.session.actions.addNewMessage({"text": "<p>Si è verificato un errore!</p>"}, 'agent', 'waiting')
                state.session.actions.unlockControls()
              }, 1000);
          });
      }, true);
      messageContent.current.appendChild(form);
    }

    if (response.actions && !snap.session.intents) {
      state.session.intents = response.actions;
     /*  const actionButtonsWrapper = document.createElement("div");
      actionButtonsWrapper.classList.add('actionButtonsWrapper');

      const sendMessage = (messageText) =>
      {
        if (!messageText) { return;}
        setTimeout(() => {
          state.session.actions.addNewMessage({"text": messageText}, 'user', 'waiting')
          state.session.actions.lockControls()
          setTimeout(() => {
              state.session.actions.addNewMessage(null, 'agent', 'loading')
          }, 500);
        }, 500);
      }

      const openLink = (url) =>
      {
        if (!url) { return;}
        window.open(url, "_blank")
      }

      response.actions.forEach(action => {

        const actionButton = document.createElement("button")
        actionButton.textContent = action.label
        if(action.message){
          actionButton.onclick = () => sendMessage(action.message);
        } else if(action.url){
          actionButton.onclick = () => openLink(action.url);

        }


        actionButtonsWrapper.appendChild(actionButton)

      });

      messageBody.current.appendChild(actionButtonsWrapper); */

    }


    const dateElement = document.createElement("small");
    dateElement.innerHTML = moment.unix(sendAt).format("HH:mm");
    messageContent.current.appendChild(dateElement);

    setTimeout(() => {
      chatScrollToBottom();
    }, 100);

    if (response.child) {
      response.child.forEach(child => {
        let childResponse = { ...child, parent: response.text };

        setTimeout(() => {
          state.session.actions.addNewMessage(childResponse, 'agent', 'waiting')
        }, 500);
      });
    }


  }, []);
  return (
    <li className={sender + ' messageElement'} key={id} ref={messageElement} >
      <div className="messageElementHead">
        {sender == 'agent' ? (
          <img
            src={snap.setup.image}
            alt={snap.setup.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src="/static/images/user.svg";
            }}
          />
        ) : (
          <img src="/static/images/user.svg" alt="User" />
        )}
      </div>
      <div ref={messageBody} className="messageBody">
        <div className="messageContent" ref={messageContent}>
        </div>
      </div>

    </li>
  );


}
