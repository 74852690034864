import React, { useRef, useEffect  } from 'react';
import { state } from '../store.js';
import { useSnapshot } from 'valtio';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export default function ChaForm() {

    const snap = useSnapshot(state)

    const messageInput = useRef()
    const messageForm = useRef()

    useEffect(() => {

        if(snap.setup.welcome){
            if(snap.session.messages.length === 0){
                setTimeout(() => {
                    state.session.actions.addNewMessage(snap.setup.welcome, 'agent', 'waiting')



                }, 1000);
            }
        }

    }, [ ]);


    const sendMessage = (e) =>
    {
        e.preventDefault();
        var messageText = messageInput.current.value;

        if (messageText) {
            messageInput.current.value = '';

            state.session.actions.addNewMessage({"text": messageText}, 'user', 'waiting')

            state.session.actions.lockControls()

            setTimeout(() => {
                state.session.actions.addNewMessage(null, 'agent', 'loading')
            }, 500);

        } else {
            messageInput.current.focus();
        }
    }



    return (
        <>
            <form id="messageForm" ref={messageForm} onSubmit={sendMessage} className={snap.setup.form ? '' : 'disabled'}>
                <input id="messageFormInput" type="text" ref={ messageInput } placeholder="What do you want to ask me?"/>
                <button className="button">

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.40121 3.39819C3.84021 2.93619 4.5862 2.8232 5.0452 3.0382C5.0452 3.0382 19.6332 10.5792 20.0082 10.7552C20.3832 10.9312 21.0102 11.3012 20.9972 11.9342C20.9842 12.5672 20.3412 12.8662 19.9792 13.0322C19.6172 13.1992 5.4102 20.6862 5.0712 20.8202C4.5862 21.0122 3.94121 20.9562 3.46821 20.5012C2.99521 20.0462 2.9902 19.3982 3.0602 18.9862C3.1612 18.3992 3.8992 14.6222 3.9762 13.9232C4.0312 13.4242 4.38821 12.9742 4.99721 12.9332C6.57221 12.8272 11.0132 12.5852 11.9952 12.4402C12.2552 12.4022 12.5152 12.2232 12.4982 11.9032C12.4812 11.5842 12.2102 11.4502 12.0122 11.4342L4.99721 10.9332C4.64021 10.9052 4.11821 10.6862 3.97321 9.92919C3.75621 8.80219 3.27221 6.3602 3.00721 4.6112C2.93421 4.1242 3.13721 3.67719 3.40121 3.39819Z" fill="black"/>
</svg>

                </button>
            </form>
        </>
    );
}
