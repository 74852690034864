import { useState, useEffect } from 'react'

import ChatHeader from './ChatHeader'
import ChatList from './ChatList'
import ChatForm from './ChatForm'
import '/styles/components/chat.css';
import IntroVideo from './IntroVideo';
import { useSnapshot } from 'valtio'
import { state } from '../store.js'
import ChatAudio from './ChatAudio';
import ChatActions from './ChatActions';
import ChatAskAi from './ChatAskAi';
import ChatIntents from './ChatIntents.jsx';




export default function Chat()
{

    const snap = useSnapshot(state)

    const saveIntroVideo = () =>
    {
        state.session.introvideo = true
    }

    useEffect(() => {
        if(snap.setup.style){
            document.documentElement.style.setProperty('--primary', snap.setup.style.primary);
            document.documentElement.style.setProperty('--primary-hover', snap.setup.style.primaryhover);
            document.documentElement.style.setProperty('--secondary', snap.setup.style.secondary);
            document.documentElement.style.setProperty('--secondary-hover', snap.setup.style.secondaryhover);
            document.documentElement.style.setProperty('--white', snap.setup.style.white);
            document.documentElement.style.setProperty('--black', snap.setup.style.black);
            document.documentElement.style.setProperty('--background-color', snap.setup.style.backgroundcolor);
            document.documentElement.style.setProperty('--background-image', `url(${snap.setup.style.backgroundimage})`);
            document.documentElement.style.setProperty('--background-image-chat', `url(${snap.setup.style.backgroundimagechat})`);
            document.documentElement.style.setProperty('--message-agent', snap.setup.style.messageagent);
            document.documentElement.style.setProperty('--message-user', snap.setup.style.messageuser);
        }
      
    }, [snap.setup]);

    return <div id='chat'>

     
    {snap.session.introvideo !== null && snap.session.introvideo !== undefined && snap.setup.introvideo ? (
        <IntroVideo closeIntroVideo={saveIntroVideo} />
        ) : (
            <>
                <ChatHeader/>
                <ChatList />
                <div id="chatControls">
                    {/* {snap.setup.actions && ( <ChatActions /> )} */}
                    <div className="row items-center relative z-100 px-4">
                        <ChatForm />
                        <ChatIntents />
                        {snap.setup.audio && ( <ChatAudio /> )}
                    </div>
                   
                </div>
                <ChatAskAi />
            </>
            
        )}
            
    
        
    </div>

}