import { useRef, useEffect, useState } from 'react'
import { state } from '../store.js'
import { useSnapshot } from 'valtio'


export default function ChatHeader()
{
    const snap = useSnapshot(state)

    const handleButtonClick = () => {
        state.session.actions.sidebarTogggle()
    };


    return <div id="ChatHeader">
        <div className="agent">
            <img 
                src={snap.setup.image} 
                alt={snap.setup.name} 
                onError={({ currentTarget }) => {
                currentTarget.onerror = null; 
                currentTarget.src="/static/images/user.svg";
                }}
            />
            <div>
                <h1>{snap.setup.name}</h1>
                <p>Online</p>
            </div>
        </div>
        {!snap.session.sidebarOpen && ( 
            <div className="actions">
                <button onClick={() => handleButtonClick()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.999 2.01648C6.47602 2.01648 1.99902 6.49348 1.99902 12.0165C1.99902 17.5395 6.47602 22.0165 11.999 22.0165C17.522 22.0165 21.999 17.5395 21.999 12.0165C21.999 6.49348 17.522 2.01648 11.999 2.01648ZM11.999 7.01648C12.551 7.01648 12.999 7.46448 12.999 8.01648C12.999 8.56848 12.551 9.01648 11.999 9.01648C11.447 9.01648 10.999 8.56848 10.999 8.01648C10.999 7.46448 11.447 7.01648 11.999 7.01648ZM10.999 10.0165C11.161 10.0165 11.873 10.0165 11.999 10.0165C12.63 10.0165 13.092 10.5855 12.968 11.2045L12.218 15.0165H12.999C13.551 15.0165 13.999 15.4645 13.999 16.0165C13.999 16.5685 13.551 17.0165 12.999 17.0165H11.999C10.816 17.0165 9.98602 15.9895 10.218 14.8285L10.811 11.9545C10.358 11.8585 9.99902 11.4985 9.99902 11.0165C9.99902 10.4645 10.447 10.0165 10.999 10.0165Z" fill="black"/>
                    </svg>
                </button>
            </div>
        )}
    </div>
}