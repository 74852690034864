import * as React from 'react';
import {useEffect, useRef} from 'react'
import gsap from "gsap";
import '/styles/components/introvideo.css';
import { state } from '../store.js'
import { useSnapshot } from 'valtio'


export default function IntroVideo({ closeIntroVideo})
{
    
    const snap = useSnapshot(state)

    const introVideoWrapper = useRef()
    const skipButton = useRef()
    const introVideo = useRef()


   
  useEffect(() => {
        introVideo.current.load();
        introVideo.current.addEventListener('loadeddata', function () {
            introVideo.current.muted = false;
            introVideo.current.play();
        }, false);

        introVideo.current.addEventListener("ended", () => {
            introVideo.current.pause();
            gsap.to(introVideoWrapper.current, {
                yPercent: -100,
                duration: 0.6,
                ease: "power4.out",
                onComplete: function () {
                    introVideoWrapper.current.style.display = "none";
                    setLocalStorage();
                }
            });
        });

        skipButton.current.addEventListener("click", () => {
            introVideo.current.pause();

            gsap.to(introVideoWrapper.current, {
                yPercent: -100,
                duration: 0.6,
                ease: "power4.out",
                onComplete: function () {
                    introVideoWrapper.current.style.display = "none";
                    setLocalStorage();
                }
            });
        });
    }, []);

    const setLocalStorage = () => {
        localStorage.setItem('introvideo', true)
        closeIntroVideo()
    };

  

    return <div id='introVideoWrapper' ref={ introVideoWrapper }>
        <video ref={ introVideo }  src={snap.setup.introvideo} playsInline></video>
        <button id="skipButton" ref={ skipButton }>
            Salta video
            <img src='data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" transform="translate(24) rotate(90)" fill="white" fill-opacity="0.01"></rect><path fill-rule="evenodd" clip-rule="evenodd" d="M18 9.99927L16.5867 8.58594L12 13.1726L7.41333 8.58594L6 9.99927L12 15.9993L18 9.99927Z" fill="%23252839"></path><mask id="mask0_4173_1154" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="6" y="8" width="12" height="8"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 9.99927L16.5867 8.58594L12 13.1726L7.41333 8.58594L6 9.99927L12 15.9993L18 9.99927Z" fill="white"></path></mask><g mask="url(%23mask0_4173_1154)"></g></svg>' alt="Salta video" />
        </button>       
    </div>
}