import * as React from 'react';
import { useSnapshot } from 'valtio'
import { state } from '../store.js'
import '/styles/components/onboarding.css';

 
export default function OnBoarding({ setOnboarding})
{
   
    const snap = useSnapshot(state)
    const onboarding = snap.setup.onboarding

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };

    const handleFinish = () => {
        localStorage.setItem('onboarding', true)
        setOnboarding()
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  

    return <div id="onboarding">
        <p className="label">{state.setup.name}</p>
        <div className="image">
            <img src={onboarding[activeStep].image} alt={onboarding[activeStep].title} />
        </div>
        <div className="content">
            <div className="container">
                {activeStep === 0 ? (
                    <h1>{onboarding[activeStep].title}</h1>
                ) : (
                    <h2>{onboarding[activeStep].title}</h2>
                )}

                {onboarding[activeStep].text && (
                    <p>{onboarding[activeStep].text}</p>
                )}

                {activeStep != 0 && (
                     <ul className="nav">
                        {onboarding.map((step, i) => {
                            if(i === 0){ return;}
                            return(
                                <li key={i}>
                                    <a className={activeStep === i ? 'active': ''}></a>
                                </li>
                            );
                        })}
                    </ul>
                )}
               
                    
                <div className="buttons">

                    {activeStep > 0 && (
                        <button className="btn btn-secondary" onClick={handleBack}>Indietro</button>
                    )}

                    {activeStep != onboarding.length - 1 ? ( 
                        <button className="btn btn-primary" onClick={handleNext}>Avanti</button>
                    ) : (
                        <button className="btn btn-primary" onClick={handleFinish}>Fine</button>
                    )}
                </div>
            </div>
        </div>
       
    </div>
}