import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio'
import { state } from '../store.js'

import '/styles/components/loader.css';


export default function Loader()
{
    const snap = useSnapshot(state)
    const navigate = useNavigate();


    useEffect(() => {

        setTimeout(() => {
            fetch( 'https://app.smartsalesassistant.io/api/get_data', { method: 'GET',})
            .then(async response => {
                if (!response.ok) {
                    // Redirect to 404
                    navigate('404')
                } else {
                    // Add Setup to Storage
                    response.json().then(data => {
                        state.setup = data
                        if(localStorage.getItem("fingerprint")){
                            state.setup.fingerprint = localStorage.getItem("fingerprint");
                        } else {
                            localStorage.setItem("fingerprint", data.fingerprint);
                        }
                    })

                }
            }, err => {
                console.log(err);
               // Redirect to 404
               navigate('404')

            });
        }, 300);

    }, [ snap.messages]);



    return <div id='loader'>
        <h3>Caricamento...</h3>
        <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
            <circle fill="#6ff" stroke="none" cx="6" cy="50" r="6">
                <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1"/>
            </circle>
            <circle fill="#6ff" stroke="none" cx="26" cy="50" r="6">
                <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2"/>
            </circle>
            <circle fill="#6ff" stroke="none" cx="46" cy="50" r="6">
                <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3"/>
            </circle>
        </svg>
    </div>

}
