import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { format, isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { it } from 'date-fns/locale';

import 'react-day-picker/dist/style.css';

const DateTimePicker = (props) => {

  const timezone = props.timezone

  if(props.style != "bullets"){

    const [selectedDate, setSelectedDate] = useState(undefined);
    const [selectedTime, setSelectedTime] = useState(undefined);
  
    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
      setSelectedTime(null); 
    };
  
    const handleTimeChange = (event) => {
      setSelectedTime(event.target.value);
    };

    return (
      <>
        <div>
          <select name="date" required onChange={handleDateChange}>
            <option value="">Seleziona una data</option>
            {props.availability.map((dateObj, index) => (
              <option key={index} value={dateObj.date}>
                {dateObj.date}
              </option>
            ))}
          </select>
        </div>
        {selectedDate && (
          <div>
            <select name="time" required onChange={handleTimeChange}>
              <option value="">Seleziona uno slot</option>
              {props.availability.find((dateObj) => dateObj.date === selectedDate).times.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
        )}        
      </>
    
    );

  } else {
    
    const dates = props.availability.map((item) => new Date(item.date));

    const startDate = new Date(dates[0]);
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);

    const startTime = props.availability.find((dateObj) => dateObj.date === format(new Date(dates[0]), 'yyyy-MM-dd')).times[0];

    const [selectedDate, setSelectedDate] = useState(startDate);
    const [selectedTime, setSelectedTime] = useState(startTime);

    const handleDateChange = (date) => {
      setSelectedDate(date);
      document.querySelectorAll('#ChatList li .messageContent .datePickerBullets ul li').forEach((button) => {
        button.classList.remove('active')
      });
      setSelectedTime(props.availability.find((dateObj) => dateObj.date === format(new Date(date), 'yyyy-MM-dd')).times[0]);
    };

    const handleTimeChange = (event) => {
      document.querySelectorAll('#ChatList li .messageContent .datePickerBullets ul li').forEach((button) => {
        button.classList.remove('active')
      });
      event.target.classList.add('active')
      setSelectedTime(event.target.textContent);
    };

    const disabledDays = (date) => !dates.some((d) => isSameDay(new Date(d), date));


    return (
      <div className='datePickerBullets'>
        <DayPicker
          fromDate={startDate}
          toDate={endDate}
          mode="single"
          selected={selectedDate}
          onSelect={handleDateChange}
          required
          defaultMonth={new Date()}
          disabled={disabledDays}
          weekStartsOn={1}
          locale={it}
        />
        {selectedDate && (
          <ul>
            {props.availability.find((dateObj) => dateObj.date === format(new Date(selectedDate), 'yyyy-MM-dd')).times.map((time, index) => (
              <li key={index} onClick={handleTimeChange} className={index === 0 ? 'active' : ''}>
                  {time}
              </li>
            ))}
          </ul>
        )}
        <input name='date' readOnly type="hidden" value={utcToZonedTime(format(selectedDate, 'yyyy-MM-dd')+'T'+selectedTime, timezone)} />
        <small>*Tutti gli orari sono riferiti alla timezone {timezone}.</small>
      </div>
    );
  }
  
};

export default DateTimePicker;

    