import React from 'react';


export default function ChatAudio() {
 

    return (
        <>
            <div id="chatAudio">
                <div className="status">
                    <div className="icon"></div>
                    <strong id="recordingCrono">00:00:00</strong>
                </div>
                <button disabled id="virRecordingButton" data-long-press-delay="300" >
                    <div className="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.99988 11C5.99988 10.4477 5.55216 10 4.99988 10C4.44759 10 3.99988 10.4477 3.99988 11C3.99988 15.0796 7.05357 18.446 10.9999 18.9381V21C10.9999 21.5523 11.4476 22 11.9999 22C12.5522 22 12.9999 21.5523 12.9999 21V18.9381C16.9462 18.446 19.9999 15.0796 19.9999 11C19.9999 10.4477 19.5522 10 18.9999 10C18.4476 10 17.9999 10.4477 17.9999 11C17.9999 14.3137 15.3136 17 11.9999 17C8.68617 17 5.99988 14.3137 5.99988 11Z" fill="black"/>
                            <path d="M8 5.99609C8 3.78695 9.79086 1.99609 12 1.99609V1.99609C14.2091 1.99609 16 3.78696 16 5.99609V10.9961C16 13.2052 14.2091 14.9961 12 14.9961V14.9961C9.79086 14.9961 8 13.2052 8 10.9961V5.99609Z" fill="black"/>
                        </svg>
                    </div>
                </button>
                <audio style={{display: 'none' }} id="siri-open" src="/static/audio/siri_open.mp3"></audio>
                <audio style={{display: 'none' }} id="siri-close" src="/static/audio/siri_close.mp3"></audio>
            </div>
        </>
    );
}
