import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const state = proxy({
    session: {
        onboarding: localStorage.getItem('onboarding') ? true : false,
        introvideo: localStorage.getItem('introvideo') ? true : false,
        actions: {
            addNewMessage: (response, sender, status) => 
                {
                    state.session.messages = [
                        ...state.session.messages,
                        {
                            id: uuidv4(),
                            response,
                            sender,
                            sendAt: moment().unix(),
                            status,
                        }
                    ]
                    if(response){
                        state.session.history = [
                            ...state.session.history,
                            {
                                response,
                                sender,
                            }
                        ]

                        const { image, video, cta, actions } = response;

                        if (image) {
                            state.session.media = [
                                ...state.session.media,
                                {
                                    "type": "image",
                                    "label": response.text,
                                    "src": response.image,
                                }
                            ]
                        }

                        if (video) {
                            state.session.media = [
                                ...state.session.media,
                                {
                                    "type": "video",
                                    "label": response.text,
                                    "src": response.video,
                                }
                            ]
                        }

                        if (cta) {
                            state.session.media = [
                                ...state.session.media,
                                {
                                    "type": "link",
                                    "label": response.cta.label,
                                    "src": response.cta.url,
                                }
                            ]
                        }

                        if (actions) {
                            actions.forEach(action => {
                                if(action.url){
                                    state.session.media = [
                                        ...state.session.media,
                                        {
                                            "type": "link",
                                            "label": action.label,
                                            "src": action.url,
                                        }
                                    ]
                                }
                            });
                        }
                    }
                    
                },
            removeLoadingMessage: () => 
                {
                    state.session.messages =  state.session.messages.filter(item => item.status !== "loading");
                },
            lockControls: () => 
            {
                document.getElementById("messageFormInput").blur()
                document.getElementById("messageForm").classList.add('disabled');
                if(document.getElementById("chatActionsToggle")){
                    document.getElementById("chatActionsToggle").classList.add('disabled');
                }
                if(document.getElementById("chatActions")){
                    document.getElementById("chatActions").classList.remove('active');
                }
                
            },
            unlockControls: () => 
            {
                document.getElementById("messageForm").classList.remove('disabled');
                if(document.getElementById("chatActionsToggle")){
                    document.getElementById("chatActionsToggle").classList.remove('disabled');
                }
                if(document.getElementById("chatActions")){
                    document.getElementById("chatActions").classList.add('active');
                }

            },
            sidebarTogggle: () => 
            {
                document.getElementById("sidebar").classList.toggle('active');
                if(document.getElementById("sidebar").classList.contains('active')){
                    state.session.sidebarOpen = window.innerWidth >= 1140 ? false : true
                } else {
                    state.session.sidebarOpen = window.innerWidth >= 1140 ? true : false
                }
            },
        },
        sidebarOpen: window.innerWidth >= 1140 ? true : false,
        messages: [],
        history: [],
        media: [],
        intents: null
    },
   
})
const unsub = devtools(state, { name: 'state', enabled: true })

  
export { state }