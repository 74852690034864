import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { state } from '../store.js';

export default function ChatAskAi() {
    const snap = useSnapshot(state);

    function getLastUserResponse(messages) {
      // Filtra gli elementi con sender "user"
      const userMessages = messages.filter(message => message.sender === "user");
    
      // Verifica se ci sono messaggi utente
      if (userMessages.length > 0) {
        // Restituisci la "response" dell'ultimo messaggio utente
        return userMessages[userMessages.length - 1].response;
      } else {
        // Nessun messaggio utente trovato
        return null;
      }
    }

    useEffect(() => {
      const isLoadingPresent = snap.session.messages.some(message => message.status === "loading");
      
      if (isLoadingPresent) {
        const lastUserResponse = getLastUserResponse(snap.session.messages);
      
        const headers = new Headers();
        headers.append( "fingerprint", localStorage.getItem("fingerprint"));

        const formData = new FormData();
        formData.append('message', lastUserResponse.text);
        formData.append('history', JSON.stringify(snap.session.history.slice(-8)));

        fetch( snap.setup.endpoint, {
          method: 'POST',
          headers: headers,
          body: formData,
         
        })
          .then(async response => {
              state.session.actions.unlockControls()
              if (!response.ok) {
                  state.session.actions.addNewMessage({"text": "Sembra esserci un problema con la connessione ad internet. Controlla la tua connessione e riprova."}, 'agent', 'waiting')
                  
              } else {
                  const answer = await response.json();
                  state.session.actions.addNewMessage(answer, 'agent', 'waiting')
              }
              state.session.actions.removeLoadingMessage();
          }, err => {
              console.log(err);
              state.session.actions.unlockControls()
              state.session.actions.removeLoadingMessage();
              state.session.actions.addNewMessage({"text": "Sembra esserci un problema con la connessione ad internet. Controlla la tua connessione e riprova."}, 'agent', 'waiting')

        });
      }

    }, [ snap.session.messages]);

  
}
