import { useMemo, useState, useEffect } from 'react'
import OnBoarding from './components/OnBoarding.jsx'
import Chat from './components/Chat.jsx'
import './styles/base/style.css';
import { useSnapshot, subscribe } from 'valtio'
import { state } from './store.js'
import Loader from './components/Loader.jsx';
import { Routes, Route } from 'react-router-dom';
import Erro404 from './pages/Erro404/Erro404.jsx';
import Sidebar from './components/Sidebar.jsx';


// TODO: 
//      Registrazione vocale
//      Long press + actions
//      Bottone info in alto a destra


export default function App()
{

    const snap = useSnapshot(state)

    // Subscribe to all state changes
    /* const unsubscribe = subscribe(state, () =>
        console.log('Messages changed to', state)
    )  */


    const saveOnboarding = () =>
    {
        state.session.onboarding = true
    }

    return (
        <>
        <Routes>
            <Route path="/" element= {!snap.setup ? (
                <Loader/>
            ) : (
                snap.setup.onboarding && snap.setup.onboarding.length != 0 ? (
                    <OnBoarding setOnboarding={saveOnboarding} />
                ) : (
                    <>
                        <Chat/>
                        <Sidebar/>
                    </>
                )
            )}  />
            <Route path="*" element={<Erro404 />} />
        </Routes>
        </>
    );
}

