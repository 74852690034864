import { useNavigate } from 'react-router-dom';
import '/styles/base/style.css';
import '/styles/components/error404.css';


const Erro404 = () => {
    const navigate = useNavigate();

    return <div id='error404'>
        <div>
            <h3>Ops..</h3>
            <p>Qualcosa è andato storto.</p>
            <button onClick={() => navigate('/')} className="button">Riprova</button>
        </div>
    </div>
};
  
  export default Erro404