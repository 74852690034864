import React, { useEffect, useState } from 'react';
import ChatMessage from "./ChatMessage";
import { useSnapshot } from 'valtio';
import { state } from '../store.js';

export default function ChatList() {
    const snap = useSnapshot(state);

    const updateMessageStatus = (index) =>
    {        
        state.session.messages[index].status = "send";
    }

    return (
        <>
            <ul id='ChatList'>
                {snap.session.messages.map((message, index) => (
                    <ChatMessage key={message.id} index={index} updateMessageStatus={updateMessageStatus} {...message} />
                ))}
            </ul>
        </>
    );
}
